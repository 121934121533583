/* Small screen styles (vertical slider) */
@media (max-width: 768px) {
  .rc-slider-vertical {
    height: 200px;
    /* Adjust height for small devices */
  }
}

/* Large screen styles (horizontal slider) */
@media (min-width: 769px) {
  .rc-slider {
    width: 100%;
    /* Adjust width for larger devices */
  }
}