.html-render-content li {
  margin-bottom: 0.75rem;
  /* Adds spacing after each li */
  line-height: 1.5;
  /* Optional: improve readability */
}

.html-render-content p {
  margin-bottom: 1rem;
}

.html-render-content ul {
  padding-left: 1.5rem;
  list-style-type: disc;
}